import React from 'react';
import './Footer.css'

const Footer = () => (
  <footer>
    &copy; Kodrich Associates Inc.
  </footer>
);

export default Footer;
